// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap"
import React from "react"
import { Helmet } from "react-helmet"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import Footer from "../Footer"
import Navbar from "../Navbar"
import "./../../styles/global.scss"

import favicon from "../../images/favicon.png"

function Layout({ children }) {
  return (
    <div className="layout">
      <Helmet
        htmlAttributes={{
          lang: "id",
        }}
      >
        <meta charSet="utf-8" />
        <meta name="language" content="indonesia" />
        <meta http-equiv="content-type" content="text/html" />
        <meta name="HandheldFriendly" content="true" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
        <meta property="og:site_name" content="Grand Wisata Bekasi" />
        <meta property="og:locale" content="id_ID" />
        <meta property="og:image" content={favicon} />
      </Helmet>
      <Navbar />
      <div className="content">{children}</div>
      <a
        className="whatsapp-button"
        href="https://wa.me/6287888570800?text=Halo Grand Wisata, saya ingin mengetahui mengenai Produk dan Promo Grand Wisata (Web)"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
        <span>Kirim WhatsApp</span>
      </a>
      <Footer />
    </div>
  )
}

export default Layout
